var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-card',{attrs:{"title":"Khách lần đầu gọi điện"}},[_c('div',{staticClass:" w-1/2"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"name":_vm.customerNameLabel,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col w-full"},[_c('vs-input',{ref:"customerNameElement",staticClass:"w-full",attrs:{"type":"text","label":_vm.customerNameLabel,"placeholder":_vm.customerPlaceholder},model:{value:(_vm.customer.name),callback:function ($$v) {_vm.$set(_vm.customer, "name", $$v)},expression:"customer.name"}}),_c('span',{staticClass:"con-text-validation span-text-validation-danger vs-input--text-validation-span span-text-validation"},[_vm._v(_vm._s(errors[0])+" ")])],1)])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('Customer phonenumber')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col w-full"},[_c('vs-input',{ref:"customerPhoneNumber",staticClass:"w-full",attrs:{"label":_vm.customerPhoneNumberLabel,"placeholder":"Nhập số điện thoại"},on:{"blur":_vm.onPhoneNumberBlur},model:{value:(_vm.customer.phoneNumber),callback:function ($$v) {_vm.$set(_vm.customer, "phoneNumber", $$v)},expression:"customer.phoneNumber"}}),_c('span',{staticClass:"con-text-validation span-text-validation-danger vs-input--text-validation-span span-text-validation"},[_vm._v(_vm._s(errors[0]))])],1)])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('Address')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col w-full"},[_c('label',{directives:[{name:"t",rawName:"v-t",value:('Address'),expression:"'Address'"}],staticClass:"vs-input--label",attrs:{"for":""}}),_c('vs-textarea',{attrs:{"placeholder":"Nhập địa chỉ"},model:{value:(_vm.customer.address),callback:function ($$v) {_vm.$set(_vm.customer, "address", $$v)},expression:"customer.address"}}),_c('span',{staticClass:"con-text-validation span-text-validation-danger vs-input--text-validation-span span-text-validation"},[_vm._v(_vm._s(errors[0]))])],1)])]}}],null,true)}),_c('ProvinceDistrictSelect',{staticClass:"mb-3",attrs:{"label":_vm.$t('Province/District'),"placeholder":'Chọn Tỉnh/TP - Quận/Huyện'},on:{"change":_vm.onDistrictChange},model:{value:(_vm.customer.districtId),callback:function ($$v) {_vm.$set(_vm.customer, "districtId", $$v)},expression:"customer.districtId"}}),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full text-right flex justify-end items-center"},[_c('button',{attrs:{"type":"submit","hidden":"true"}}),_c('vs-button',{directives:[{name:"t",rawName:"v-t",value:('Save'),expression:"'Save'"}],staticClass:"mb-2",attrs:{"id":"btnSave","disabled":invalid || _vm.existingCustomer != null || !_vm.isHasSalesChannel},on:{"click":_vm.save}})],1)])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }