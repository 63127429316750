import {CustomerStatus, CustomerType} from '@/constants/Customer';
import {BankAccount} from '../bank/BankAccount';
import {CustomerAddress} from './CustomerAddress';
import {Attribute} from '../attribute/Attribute';
import {Category} from '../category/category';
import {Social} from '../social/social';
import {Note} from '../note/Note';
import {Share} from '../share/Share';

export class Customer {
    id: string;
    name: string;
    salesChannelId: string;
    salesChannelName: string;
    code: string;
    shortName: string;
    website: string;
    taxCode: string;
    foundingDate: string | null;
    managerId: string;
    managerFullName: string;
    bciCode: string;
    representative: string;
    representativeTitle: string;
    representativeOffice: string;
    description: string;
    status: CustomerStatus | null;
    isActive: boolean;
    phoneNumber: string;
    type: CustomerType;
    resourceId: string;
    resourceName: string;
    classifyId: string;
    classifyName: string;
    potentialLevelId: string;
    potentialLevelName: string;
    clarificationId: string;
    clarificationName: string;
    strategyId: string;
    strategyName: string;
    focusGroupId: string;
    focusGroupName: string;
    companySize: number;
    businessCareerId: string;
    businessCareerName: string;
    revenue: number;
    image: string;
    agencyId: string;
    agencyName: string;
    customerId: string;
    isApproved: string;
    agencyAncestors: string[];
    bankAccounts: BankAccount[];
    addresses: CustomerAddress[];
    attributes: Attribute[];
    categories: Category[];
    revenues: any;
    socials: Social[];
    notes: Note[];
    share: Share[];
    contacts: Customer[];
    tasks: any;
    creatorId: string;
    creatorFullName: string;
    concurrencyStamp: string;
    email: string;
    provinceId: string;
    provinceName: string;
    districtId: string;
    districtName: string;
    contactName: string;
    address: string;
    roleId: string;
    roleName: string;
    erpCode?: string;
    gender: number = 0;
    /**
     *
     */
    constructor() {
        this.id = '';
        this.name = '';
        this.email = '';
        this.phoneNumber = '';
        this.provinceId = '';
        this.provinceName = '';
        this.districtId = '';
        this.districtName = '';
        this.type = CustomerType.personal;
        this.salesChannelId = '';
        this.salesChannelName = '';
        this.managerId = '';
        this.managerFullName = '';
    }
}
