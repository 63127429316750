






import {Component, Prop, Vue} from "vue-property-decorator";
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

@Component({
    components: {
        Treeselect
    }
})
export default class TreeSelect extends Vue {
    @Prop() value!: any;
    @Prop({default: false}) isMultiple!: boolean;
    @Prop({default: () => []}) data!: any;

    updateValue(value: any) {
        this.$emit('input', this.value);
        this.$emit('selected', this.value);
    }

    onTreeSelect(){
        this.$emit('selected', this.value);
    }

}
