















import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {ProvinceDistrictResult} from "@/models/ProvinceDistrictResult";
import {SearchResult} from "@/models/SearchResult";
import axios from 'axios';
import {ActionResult} from "@/models/ActionResult";

@Component
export default class ProvinceDistrictSelect extends Vue {
    @Prop() label!: string;
    @Prop({default: false}) isMultiple!: string;
    @Prop() value!: string | string[];
    @Prop({default: false}) disabled!: boolean;
    @Prop({default: ''}) placeholder!: string;

    selected: string | string[] = '';
    dataSource: any = [];

    @Watch('value')
    onSelectedDataChange(value: any, oldValue: any) {
        this.selected = value;
    }

    @Watch('selected')
    onSelectedChange(value: any, oldValue: any) {
        this.emitChangeEvent(value);
        this.$emit('input', value);
    }

    private url = process.env.VUE_APP_CORE_URL;

    constructor() {
        super();
    }

    mounted() {
        this.selected = this.value;
        const response = axios.get(`${this.url}api/v1/provinces`, {
            params: {
                nationalId: '1'
            }
        }) as Promise<SearchResult<ProvinceDistrictResult>>;
        response.then((result: SearchResult<ProvinceDistrictResult>) => {
            if (result.code > 0) {
                this.dataSource = result.items.map((provinceDistrict: ProvinceDistrictResult) => {
                    return {
                        id: provinceDistrict.districtId,
                        text: `${provinceDistrict.provinceName} - ${provinceDistrict.districtName}`,
                        data: provinceDistrict
                    }
                })
            }
        });
    }

    inputChange(e: any) {
    }

    private emitChangeEvent(value: any) {
        const selectedProvinceDistrict = this.dataSource.find((provinceDistrict: any) => {
            return provinceDistrict.id === value;
        });
        this.$emit('change', selectedProvinceDistrict != null && selectedProvinceDistrict != undefined
            ? selectedProvinceDistrict.data : null);
    }
}
