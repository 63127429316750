

















































































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { Customer } from "@/models/customer/Customer";
import { CustomerService } from "@/services/customer/CustomerService";
import { ActionResult } from "@/models/ActionResult";
import CustomerGroupTreeDropdown from "@/components/CustomerGroupTreeDropdown.vue";
import { UserConfigResult, UserService } from "@/services/UserService";
import { Getter, namespace } from "vuex-class";
import UserSuggestion from "@/components/suggestion/user-suggestion/UserSuggestion.vue";
import TreeSelect from "@/components/TreeSelect.vue";
import i18n from "@/i18n";
import { CustomerConfig } from "@/models/customer/CustomerConfig";
import { CustomerType } from "@/constants/Customer";
import { CustomerResult } from "@/models/customer/CustomerResult";
import vSelect from 'vue-select';
import { SuggestionModel } from "@/components/suggestion/base-suggestion/SuggestionModel";
import { ProvinceDistrictResult } from "@/models/ProvinceDistrictResult";
import ProvinceDistrictSelect from "@/components/ProvinceDistrictSelect.vue";
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { CategoryType } from "@/models/category/CategoryType";
import CategorySelect from "@/components/category/CategorySelect.vue";
import router from '@/router';

extend("required", {
    ...required,
    message: (_: string, values: any) => i18n.t("validations.required", [_])
} as any);
const customerStoreModule = namespace('customer');
@Component({
    components: {
        CustomerGroupTreeDropdown,
        UserSuggestion,
        TreeSelect,
        ValidationObserver,
        ValidationProvider,
        vSelect,
        ProvinceDistrictSelect,
        VuePerfectScrollbar,
        CategorySelect
    }
})
export default class CustomerQuickInsert extends Vue {
    @Ref("customerPhoneNumber") customerPhoneNumber!: any;
    @Ref("customerNameElement") customerNameElement!: any;
    @Ref("customerNameInput") customerNameInput!: HTMLFormElement;
    @Ref("salesChannelTree") salesChannelTree!: any;
    @Prop({default: true}) isRedirect!: boolean;
    @Getter("oidcUser") oidcUser!: any;
    @customerStoreModule.Action('fetchConfig') fetchConfig!: any;
    @customerStoreModule.Getter('config') config!: CustomerConfig;
    @customerStoreModule.Getter('isConfigLoaded') isConfigLoaded!: boolean;

    isCreateAnother = false;
    isShowFull = false;
    note: string = "";
    listProvince = [];
    isCustomerExists = false;
    customer: Customer = new Customer();
    existingCustomer: Customer | null = null;
    isShow = false;
    //counterDanger = false;
    customerType = CustomerType;
    categoryType = CategoryType;

    userConfig: UserConfigResult | null = null;
    // listCustomerType = [
    //     {value: 0, text: "Cá nhân"},
    //     {value: 1, text: "Doanh nghiệp"}
    // ];
    selectedManager: any = [];
    // listCustomerGroup = [];
    private customerService: CustomerService;

    // onCustomerTypeChange() {
    //     this.focusCustomerName();
    // }

    constructor() {
        super();
        this.customerService = new CustomerService();

    }
    //
    // get selectedRole() {
    //     return this.customer != null && this.customer.roleId ? [{
    //         id: this.customer.roleId,
    //         text: this.customer.roleName
    //     }] : [];
    // }

    mounted() {
        setTimeout(() => {
            this.fetchConfig()
                .then();
        });

        this.customer.phoneNumber = this.$route.params.mobile;
        this.customer.name = this.$route.params.mobile;
    }

    @Watch("isShow")
    onPopupShown(value: boolean, oldValue: boolean) {
        if (value) {
            this.focusCustomerName();
            this.setConfig();
            this.customer.managerId = this.currentUser.userId;
            this.customer.managerFullName = this.currentUser.fullName;
            this.updateManager();
        }
    }

    get currentUser() {
        return this.oidcUser;
    }

    get isHasSalesChannel() {
        return this.config.defaultSalesChannelId != null && this.config.defaultSalesChannelId !== '' && this.config.defaultSalesChannelId !== undefined;
    }

    get customerNameLabel() {
        return this.customer.type == CustomerType.personal
            ? this.$t('Customer name')
            : this.$t('Company name');
    }

    get customerPlaceholder() {
        return this.customer.type === this.customerType.personal
            ? 'Nhập tên khách hàng'
            : 'Nhập tên công ty';
    }

    get customerPhoneNumberLabel() {
        return this.customer.type === this.customerType.personal
            ? 'Số điện thoại người liên hệ'
            : 'Số điện thoại khách hàng';
    }

    // testClick()
    // {
    //     window.open("/callCenter/new/0888622086/210390213123", '_blank');
    // }
    async onPhoneNumberBlur(e: any) {
        const value = e.target.value;
        this.existingCustomer = null;
        this.customer.phoneNumber = value;
        if (!this.customer.phoneNumber) {
            this.isCustomerExists = false;
        } else {
            this.showLoading();
            this.existingCustomer = await this.customerService.getCustomerByPhoneNumber(
                this.customer.phoneNumber
            );
            if (this.existingCustomer) {
                this.customer.name = this.existingCustomer.name;
            }
            this.showLoading(false);
        }
    }

    // onManagerSelected(suggestions: SuggestionModel[]) {
    //     const suggestion = suggestions[0];
    //     if (suggestion) {
    //         this.customer.managerId = suggestion.id;
    //         this.customer.managerFullName = suggestion.text;
    //     }
    // }

    onDistrictChange(provinceDistrict: ProvinceDistrictResult) {
        if (this.customer) {
            this.customer.provinceId = provinceDistrict ? provinceDistrict.provinceId : '';
            this.customer.provinceName = provinceDistrict ? provinceDistrict.provinceName : '';
            this.customer.districtId = provinceDistrict ? provinceDistrict.districtId : '';
            this.customer.districtName = provinceDistrict ? provinceDistrict.districtName : '';
        }
    }

    // onManagerRemoved() {
    //     this.customer.managerId = this.currentUser.userId;
    //     this.customer.managerFullName = this.currentUser.fullName;
    //     this.updateManager();
    // }

    add(customerName?: string) {
        this.resetModel();
        this.isShow = true;
        if (customerName) {
            this.customer.name = customerName;
        }
    }

    async save(e: any) {
        e.preventDefault();
        if (this.isCustomerExists && this.customer.id) {
            // this.closePopup();
            await this.$router.push({
                name: "customerDetail",
                params: {id: this.customer.id.toString()}
            });
        } else {
            try {
                this.showLoading();

                this.customer.resourceId = "c3e38823-ac25-43b2-927a-19c20ff83ec1";
                this.customer.resourceName = "CallCenterMocMedia";

                const result: ActionResult<CustomerResult> = await this.customerService.insert(
                    this.customer
                );
                this.showLoading(false);
                if (result.code > 0 && result.data) {
                        // this.closePopup();

                        if (!this.isRedirect) {
                            this.$emit("savedSuccess", result.data);
                            return;
                        }

                        if (this.currentUser.userId !== this.customer.managerId) {
                            this.$vs.notify({
                                title: "Thông báo",
                                text: result.message,
                                color: 'success',
                                iconPack: 'feather',
                                icon: 'icon-info'
                            });

                            await router.push({ path: `/callCenter/${result.data.id}/${this.$route.params.callId}/activity` });
                        } else {
                            await this.$router.push({
                                name: "customerDetail",
                                params: {id: result.data.id.toString()}
                            });
                        }
                }
            } catch (response) {
                //console.log(response);
                this.showLoading(false);
                this.$vs.notify({
                    title: "Thông báo",
                    text: response.message,
                    color: 'danger',
                    iconPack: 'feather', icon: 'icon-info'
                });
            }
        }
    }
    //
    // closePopup() {
    //     this.isShow = false;
    // }

    private focusPhoneNumberInput() {
        setTimeout(() => {
            this.customerPhoneNumber.$el.querySelector("input").focus();
        }, 100);
    }

    private focusCustomerName() {
        setTimeout(() => {
            this.customerNameElement.$el.querySelector("input").focus();
        }, 100);
    }

    private resetModel() {
        this.customer.phoneNumber = "";
        this.customer.name = "";
        this.note = "";
        this.customer.provinceId = "";
        this.customer.districtId = "";
        this.isCustomerExists = false;
        this.existingCustomer = null;
    }

    showLoading(isLoading: boolean = true) {
        if (isLoading) {
            this.$vs.loading();
        } else {
            this.$vs.loading.close();
        }
    }

    // private async getUserConfig() {
    //     if (this.userConfig == null) {
    //         const userService = new UserService();
    //         this.userConfig = await userService.getConfig(
    //             this.currentUser.userId
    //         );
    //     } else {
    //         console.log("Somethign went wrong");
    //     }
    // }

    private setConfig() {
        if (this.config) {
            this.customer.type = this.config.defaultCustomerType;
            this.customer.salesChannelId = this.config.defaultSalesChannelId;
            this.customer.salesChannelName = this.config.defaultSalesChannelName;
        } else {
            this.customer.type = CustomerType.personal;
            this.customer.salesChannelId = '';
            this.customer.salesChannelName = '';
        }
        this.salesChannelTree.getAllTreeData();
    }

    private updateManager() {
        this.selectedManager = [{id: this.customer.managerId, text: this.customer.managerFullName}];
    }
}
